import { yupResolver } from '@hookform/resolvers/yup';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Box, Grid, MenuItem, Paper, TextField, Typography, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextField from '../../../components/textfield/CustomTextField';
import { validationSchema } from '../../../config/consts';
import { setCompletedOrder, setPaymentDetails, setPaymentMethod } from '../../../store/checkout/actions/checkoutActions';
import PaymentMethodCard from './PaymentMethodCard';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useNavigate } from 'react-router';
import { selectCartType, selectOrderTotal, selectedPaymentMethod } from '../../../store/checkout/selectors/checkoutSelectors';
import { submitOrder } from '../../../store/checkout/services/checkoutService';
import { selectUserDetails } from '../../../store/selectors/userSelectors.js';
import { NotificationToaster } from '../../../utils/notificationToaster.js';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import CustomCheckbox from '../../../components/checkboxes/customCheckbox.jsx';

const Label = styled('label')({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const calculateTax = (orderTotal, taxType) => {
  if (taxType === 'adult') {
    return 0.2 * orderTotal;
  } else {
    return 0.07 * orderTotal;
  }
};

const PaymentForm = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const items = useSelector((state) => state.checkout.items);
  const paymentMethod = useSelector(selectedPaymentMethod);
  const itemsTotal = useSelector(selectOrderTotal);
  const taxType = useSelector(selectCartType);
  const userInfo = useSelector(selectUserDetails);
  const newUserPhoneNumber = useSelector((state) => state.user.userPhoneNumber);
  const [emailOptIn, setEmailOptIn] = useState(false);

  const taxCalc = calculateTax(itemsTotal, taxType);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const formatDateField = (date) => {
    var tempDate = new Date(date);
    return tempDate.toLocaleDateString('en-US');
  };

  const validateData = (data) => {
    if (data.pickupTime === '0') {
      setError('pickupTime', { message: 'Please select a pickup time' });
      return false;
    }

    if (taxType === 'adult') {
      return true;
    }

    var validate = true;
    if (data.birthday === null || data.birthday === undefined || data.birthday === 'MM/DD/YYYY') {
      setError('birthday', { message: 'Date must be a valid date in format MM/DD/YYYY' });
      validate = false;
    }
    if (data.registrationDate === null || data.registrationDate === undefined || data.registrationDate === 'MM/DD/YYYY') {
      setError('registrationDate', { message: 'Date must be a valid date in format MM/DD/YYYY' });
      validate = false;
    }
    if (data.expirationDate === null || data.expirationDate === undefined || data.expirationDate === 'MM/DD/YYYY') {
      setError('expirationDate', { message: 'Date must be a valid date in format MM/DD/YYYY' });
      validate = false;
    }
    return validate;
  };

  const calculateTotalAmount = () => {
    var totalAmount = itemsTotal + taxCalc;

    if (paymentMethod === 'CREDIT CARD' || paymentMethod === 'DEBIT CARD') {
      totalAmount = totalAmount + 3.5;
    }
    return totalAmount;
  };

  const createAPIPayload = async (data) => {
    const apiPayload = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        birthday: data.birthday,
        phoneNumber: data.phoneNumber
      },
      items: items.map((item) => ({
        productId: item.id,
        quantity: item.quantity
      })),
      shipping: {
        method: 'Standard',
        address: '123 Main St, Some City, Some Country',
        cost: 5.99
      },
      paymentMethod: paymentMethod,
      totalAmount: calculateTotalAmount(),
      discount: 0,
      tax: taxCalc,
      orderType: taxType,
      pickUpTimeFrame: data.pickupTime,
      medicalId: data.medicalId,
      registrationDate: data.registrationDate,
      expiryDate: data.expirationDate,
      emailOptIn: emailOptIn
    };

    try {
      mixpanel.people.set_once({
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        email: data?.emailAddress ?? '',
        birthday: data?.birthday ?? '',
        phoneNumber: data?.phoneNumber ?? ''
      });
    } catch (error) {
      console.log(error);
    }

    return apiPayload;
  };

  const onSubmit = async (data) => {
    if (validateData(data)) {
      const sevenDaysFromNow = new Date();
      const now = new Date();
      sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
      console.log('************** Old Data **************');
      console.log(data);
      console.log('************** ');
      data = {
        ...data,
        birthday: formatDateField(data.birthday),
        registrationDate: formatDateField(now),
        expirationDate: formatDateField(sevenDaysFromNow)
      };

      const payload = await createAPIPayload(data);
      console.log('************** payload **************');
      console.log(payload);
      console.log('************** payload **************');

      try {
        // setIsSubmitting(true);
        dispatch(setPaymentDetails(data));
        var orderNumber = '';
        submitOrder(payload)
          .then((response) => {
            console.log(response.data.orderNumber);
            orderNumber = response.data.orderNumber;
            dispatch(setCompletedOrder({ ...payload, orderNumber: orderNumber }));
            navigate('/confirmation');
          })
          .catch((err) => {
            NotificationToaster({ message: err.response.data.message, type: 'error' });
          });
      } catch (error) {
        console.error(error);
      } finally {
        // setIsSubmitting(false);
      }

      return '';
    }
  };

  const handlePaymentMethodSelect = (method) => {
    dispatch(setPaymentMethod(method));
  };

  const formatPhoneNumber = (numericPhoneNumber) => {
    const regex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = numericPhoneNumber.match(regex);

    if (matches) {
      return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
    }

    return numericPhoneNumber;
  };

  const onChangeHandler = (e) => {
    const inputNumeric = e.target.value.replace(/\D/g, '');
    const formattedPhoneNumber = formatPhoneNumber(inputNumeric);
    setPhoneNumber(formattedPhoneNumber);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      return new Promise((resolve, reject) => {
        handleSubmit(
          async (data) => {
            resolve(await onSubmit(data));
          },
          (errors) => {
            reject(errors);
          }
        )();
      });
    }
  }));

  const format12Hour = (time) => {
    const hours = time.getHours() % 12 || 12;
    const minutes = ('0' + time.getMinutes()).slice(-2);
    const period = time.getHours() < 12 ? 'AM' : 'PM';
    return `${hours}:${minutes} ${period}`;
  };

  const generateTimeIntervals = () => {
    const intervals = [];
    intervals.push({ label: 'Select Pickup Time', value: '0' });
    const currentTime = new Date();
    let startTime = new Date(currentTime);

    startTime.setMinutes(Math.ceil(currentTime.getMinutes() / 15) * 15);

    for (let i = 0; i < 36; i++) {
      const endTime = new Date(startTime.getTime() + 15 * 60000);
      const timeString = format12Hour(startTime) + ' - ' + format12Hour(endTime);
      intervals.push({ label: timeString, value: timeString });
      startTime = endTime;

      if (endTime.getHours() === 22 && endTime.getMinutes() === 0) {
        break;
      }
    }
    setTimeIntervals(intervals);
  };

  useEffect(() => {
    dispatch(setPaymentMethod('CASH'));
    generateTimeIntervals();

    if (userInfo) {
      setValue('firstName', userInfo.firstName);
      setValue('lastName', userInfo.lastName);
      setValue('phoneNumber', userInfo.phoneNumber);
    } else {
      setValue('phoneNumber', newUserPhoneNumber);
    }

    if (taxType === 'adult') {
      setValue('medicalId', 0);
    }
  }, []);

  return (
    <Paper elevation={0}>
      <Box>
        <form style={{ backgroundColor: '#ff' }} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={6} mb={2}>
              <CustomTextField label="First Name*" name="firstName" register={register} errors={errors} loading={loading} />
            </Grid>

            <Grid item xs={6} mb={2}>
              <CustomTextField label="Last Name*" name="lastName" register={register} errors={errors} loading={loading} />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} mb={2}>
              <CustomTextField
                label="Phone Number*"
                name="phoneNumber"
                placeholder="(___) ___-____"
                register={register}
                errors={errors}
                loading={loading}
                onChange={onChangeHandler}
                value={phoneNumber}
                inputProps={{ maxLength: 10 }}
              />{' '}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} mb={2}>
              <Label>Birthday*</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DatePicker
                        disableFuture
                        maxDate={dayjs().subtract(13, 'year')}
                        variant="outlined"
                        disabled={loading}
                        name="birthday"
                        placeholder="MM-DD-YYYY"
                        value={value}
                        onChange={onChange}
                        slotProps={{ textField: { fullWidth: true, error: !!errors.birthday, helperText: errors?.birthday?.message } }}
                        sx={{
                          '&  .MuiFormHelperText-root.Mui-error': {
                            backgroundColor: 'transparent',
                            marginLeft: 0
                          },
                          '.css-1tijb0f-MuiInputBase-root-MuiOutlinedInput-root': {
                            color: '#000066',
                            backgroundColor: '#F1EEEB'
                          }
                        }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>{' '}
            </Grid>
          </Grid>

          {taxType === 'medical' && (
            <Grid container spacing={4}>
              <Grid item xs={12} mb={2}>
                <CustomTextField label="Medical ID*" name="medicalId" register={register} errors={errors} loading={loading} />
              </Grid>
            </Grid>
          )}

          {taxType === 'medical' && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} mb={2}>
                  <Label>Registration Date*</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="registrationDate"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <DatePicker
                            disableFuture
                            maxDate={dayjs()}
                            variant="outlined"
                            disabled={loading}
                            name="registrationDate"
                            placeholder="MM-DD-YYYY"
                            value={value}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!errors.registrationDate,
                                helperText: errors?.registrationDate?.message
                              }
                            }}
                            sx={{
                              '&  .MuiFormHelperText-root.Mui-error': {
                                backgroundColor: 'transparent',
                                marginLeft: 0
                              },
                              '.css-1tijb0f-MuiInputBase-root-MuiOutlinedInput-root': {
                                color: '#000066',
                                backgroundColor: '#F1EEEB'
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} mb={2}>
                  <Label>Expiration Date*</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="expirationDate"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <DatePicker
                            disablePast
                            minDate={dayjs()}
                            variant="outlined"
                            disabled={loading}
                            name="expirationDate"
                            placeholder="MM-DD-YYYY"
                            value={value}
                            onChange={onChange}
                            slotProps={{
                              textField: { fullWidth: true, error: !!errors.expirationDate, helperText: errors?.expirationDate?.message }
                            }}
                            sx={{
                              '&  .MuiFormHelperText-root.Mui-error': {
                                backgroundColor: 'transparent',
                                marginLeft: 0
                              },
                              '.css-1tijb0f-MuiInputBase-root-MuiOutlinedInput-root': {
                                color: '#000066',
                                backgroundColor: '#F1EEEB'
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container spacing={4}>
            <Grid item xs={12} mb={2}>
              <CustomTextField label="Email Address (Optional)" name="emailAddress" register={register} errors={errors} loading={loading} />{' '}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginBottom: '1.2em' }}>
            <Grid item xs={12} mb={2}>
              <Label>Select Pickup Time*</Label>
              <TextField
                variant="outlined"
                fullWidth
                select
                {...register('pickupTime', { required: `Select Pickup Time* is required` })}
                error={!!errors.pickupTime}
                helperText={errors?.pickupTime?.message}
                disabled={loading}
                InputLabelProps={{ shrink: false }}
                onChange={(e) => {
                  props.setPickupTime(e.target.value);
                }}
                defaultValue={'0'}
                sx={{
                  '& input': {
                    color: '#00000066',
                    backgroundColor: '#F1EEEB'
                  },
                  '&  .MuiFormHelperText-root.Mui-error': {
                    backgroundColor: 'transparent',
                    marginLeft: 0
                  },
                  '.css-o8pmqo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                    color: '#00000066',
                    backgroundColor: '#F1EEEB'
                  }
                }}
              >
                {timeIntervals.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {/* <InputLabel>Text</InputLabel> */}
                    <Typography display="inline" style={{ fontSize: '15px', fontFamily: 'Inter' }}>
                      {option.value !== '0' ? 'Today: ' : ''}
                    </Typography>
                    <Typography display="inline" style={{ fontSize: '15px', fontFamily: 'Inter', fontWeight: '600' }}>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={emailOptIn}
                    onChange={(event) => {
                      setEmailOptIn(event.target.checked);
                    }}
                  />
                }
                label="Opt-in to receive text/email messages about your order"
              />
            </Grid>
          </Grid>
          <Typography fontWeight="bold" variant="body">
            Select Payment Method
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid style={{ marginTop: '1em' }} item xs={6}>
              <PaymentMethodCard
                icon={<LocalAtmIcon style={{ fontSize: '4.5em' }} />}
                title="CASH"
                fee="No Fee"
                description=""
                onClick={() => handlePaymentMethodSelect('CASH')}
              />
            </Grid>

            <Grid style={{ marginTop: '1em' }} item xs={6}>
              <PaymentMethodCard
                icon={<CreditCardIcon style={{ fontSize: '4.5em' }} />}
                title="CREDIT CARD"
                fee="$3.50 Fee"
                description=""
                onClick={() => handlePaymentMethodSelect('CREDIT CARD')}
              />
            </Grid>

            <Grid style={{ marginTop: '1em' }} item xs={6}>
              <PaymentMethodCard
                icon={<AccountBalanceIcon style={{ fontSize: '4.5em' }} />}
                title="DEBIT CARD"
                fee="$3.50 Fee"
                description=""
                onClick={() => handlePaymentMethodSelect('DEBIT CARD')}
              />
            </Grid>

            <Grid style={{ marginTop: '1em' }} item xs={6}>
              <PaymentMethodCard
                icon={<CreditCardIcon style={{ fontSize: '4.5em' }} />}
                title="CANPAY"
                fee=""
                description="In-Store Only"
                onClick={() => handlePaymentMethodSelect('CANPAY')}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
});

export default PaymentForm;
